<template>
  <CommonDialog v-bind="props" @close="$emit('close')">
    <template #title>{{ $t('add-to-home-modal.modal-title') }}</template>
    <template #content>
      <h2 class="text-2xl">
        {{ $t('add-to-home-modal.notification-instruction') }}
      </h2>

      <div class="mt-4">
        <p class="mb-2 font-bold">{{ $t('add-to-home-modal.ios-label') }}</p>
        <ol class="list-inside list-decimal">
          <li>{{ $t('add-to-home-modal.ios-instructions-1') }}</li>
          <li>{{ $t('add-to-home-modal.ios-instructions-2') }}</li>
          <li>{{ $t('add-to-home-modal.ios-instructions-3') }}</li>
        </ol>
      </div>

      <div class="mt-4">
        <p class="mb-2 font-bold">
          {{ $t('add-to-home-modal.android-label') }}
        </p>
        <ol class="list-inside list-decimal">
          <li>{{ $t('add-to-home-modal.android-instructions-1') }}</li>
          <li>{{ $t('add-to-home-modal.android-instructions-2') }}</li>
          <li>{{ $t('add-to-home-modal.android-instructions-3') }}</li>
        </ol>
      </div>

      <p class="mt-4">{{ $t('add-to-home-modal.additional-info') }}</p>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import type { DialogProps } from '@autobid/ui/types/components/Dialog'

defineEmits(['close'])

const props = defineProps<DialogProps>()
</script>
